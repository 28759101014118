import React from "react";

import Layout from "../components/organisms/layout";
import Footer from "../components/organisms/footer";
import Header from "../components/organisms/header";
import Contact from "../components/organisms/contact";

//import HeaderMovie from "../components/headermovie";

import Nav from "../components/molecules/nav";
import LightboxGallery from "../components/molecules/lightboxgallery";

import SEO from "../components/molecules/seo";

import "../scss/molecules/_gallery.scss";
import "../scss/molecules/_quote-block.scss";

import headerImage from "../../static/design/img/header/pages/ritorno-in-het-park.jpg";

const Sfeer = () => (
  <Layout>
    <SEO
      title="Media"
      description="Ontdek onze foto's. Onze pizza ovens worden authentiek gestookt met hout. Dit zorgt voor de overheerlijke smaak van de pizza. De ovens zijn geïsoleerd en dus veilig voor uw kinderen. Ons kraam past perfect in Uw tuin. Elegant met zijn houten afwerking en op 15 minuten opgesteld. Vanaf dan kunnen wij Uw pizzafeest verzorgen met de hoogste kwaliteit in acht."
    />

    <Nav />

    <Header image={headerImage} darkBg={true} />

    {/* <section className="quote-block section-dark pt-5 pb-6">
      <div className="container">
        <h2 className="title dark-bg">Wat zeggen onze klanten</h2>
        <h4 className="h5 dark-bg mb-4">
          Een greep uit onze{" "}
          <a href="https://www.facebook.com/Ritorno.be/">Facebook pagina</a>
        </h4>
        <div className="row eh-grid">
          <div className="col-sm-4">
            <div className="quote editContent">
              <img
                src="/design/img/customer-photo.jpg"
                alt="Generieke profiel afbeelding"
                height="45"
                width="45"
              />
              <div className="quote-stars">
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
              </div>
              <p>
                Net een lentefeest achter de rug dat werd verzorgd door het
                professionele team van Ritorno. Zeer veel variatie in de
                pizza's, die allemaal even verrukkelijk waren. Keuze genoeg voor
                vleeseters, visliefhebbers en vegetariërs. Deze mannen wisten
                duidelijk waar ze mee bezig waren. En het beste van alles: geen
                afwas of opkuis nadien! Mocht je nog twijfelen: de perfecte
                verhouding prijs, kwaliteit en service ga je nergens anders
                vinden!
              </p>
              <small>Karel</small>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="quote editContent">
              <img
                src="/design/img/customer-photo.jpg"
                alt="Generieke profiel afbeelding"
                height="45"
                width="45"
              />
              <div className="quote-stars">
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
              </div>
              <p>
                Een heel dikke "merci" aan de mensen van Ritorno om onze
                huwelijksfuif net dat beetje (véél) extra te geven met jullie
                concept!! Iedereen was vol lof! Er zijn alleen maar
                "super"-latieven: super-lekkere pizza's, super-snel klaar,
                super-vriendelijke en professionele service! Een echt aanrader!!
              </p>
              <small>Sophie</small>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="quote editContent">
              <img
                src="/design/img/customer-photo.jpg"
                alt="Generieke profiel afbeelding"
                height="45"
                width="45"
              />
              <div className="quote-stars">
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
              </div>
              <p>
                We wouden net iets extra doen voor ons feest. De snelle en
                professionele houding via e-mail overtuigde ons al snel om het
                vertrouwen aan Ritorno te gunnen. Vanaf de aankomst tot het
                einde was het een unieke en lekkere beleving!
                <br />3 enthousiastelingen voorzien de aanwezigen van
                overheerlijke pizza's. Naast enkele standaard pizza's werden ook
                heel wat (voor mij) nieuwe creaties gemaakt. Wij maken zeker nog
                gebruik. Een echte aanrader.
              </p>
              <small>Tom</small>
            </div>
          </div>
        </div>
        <div className="row eh-grid">
          <div className="col-sm-4">
            <div className="quote editContent">
              <img
                src="/design/img/customer-photo.jpg"
                alt="Generieke profiel afbeelding"
                height="45"
                width="45"
              />
              <div className="quote-stars">
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
              </div>
              <p>
                Ritorno kwam pizza's bakken op de babyborrel van onze dochter.
                Contact verliep vlot, alsook de afgesproken timing. Kortom: sit
                back, relax &amp; eat pizza's Bedankt Ritorno!!
              </p>
              <small>Sam</small>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="quote editContent">
              <img
                src="/design/img/customer-photo.jpg"
                alt="Generieke profiel afbeelding"
                height="45"
                width="45"
              />
              <div className="quote-stars">
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
              </div>
              <p>
                Alles was goed in orde. Iedereen op ons feestje was super
                enthousiast en vonden de pizza's heel lekker. Het was eens iets
                anders dan de klassieke barbecues. Dank je wel Ritorno voor de
                goede service, heerlijke pizza's, vriendelijke bediening en zo
                veel meer. Zeker een aanrader, en voor ons, voor herhaling
                vatbaar.
              </p>
              <small>Britt</small>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="quote editContent">
              <img
                src="/design/img/customer-photo.jpg"
                alt="Generieke profiel afbeelding"
                height="45"
                width="45"
              />
              <div className="quote-stars">
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
                <i className="icon-star" />
              </div>
              <p>
                Het was echt een goede ervaring. De professionaliteit van het
                personeel zorgde ervoor dat onze gasten niets te kort kwamen.
              </p>
              <small>Melissa</small>
            </div>
          </div>
        </div>
      </div>
    </section> */}

    <section
      className="pattern-bg pt-5 pb-8"
      style={{ backgroundImage: "url(/design/img/pattern-background.png)" }}
    >
      <div className="container gallery">
        <h1 className="h2 title text-center">Foto's</h1>
        <LightboxGallery
          images={[
            {
              url: "/design/img/2020/media/IMG_6886.jpeg",
              thumb: "/design/img/2020/media-thumb/IMG_6886.jpg",
              title: "",
            },
            {
              url: "/design/img/2020/media/IMG_6893.jpeg",
              thumb: "/design/img/2020/media-thumb/IMG_6893.jpg",
              title: "",
            },
            {
              url: "/design/img/2020/media/IMG_6928.jpeg",
              thumb: "/design/img/2020/media-thumb/IMG_6928.jpg",
              title: "",
            },

            {
              url: "/design/img/2020/media/IMG_6888.jpeg",
              thumb: "/design/img/2020/media-thumb/IMG_6888.jpg",
              title: "",
            },
            {
              url: "/design/img/2020/media/IMG_6948.jpeg",
              thumb: "/design/img/2020/media-thumb/IMG_6948.jpg",
              title: "",
            },

            {
              url: "/design/img/2020/media/IMG_6960.jpeg",
              thumb: "/design/img/2020/media-thumb/IMG_6960.jpg",
              title: "",
            },
            {
              url: "/design/img/2020/media/IMG_7046.jpeg",
              thumb: "/design/img/2020/media-thumb/IMG_7046.jpg",
              title: "",
            },
            {
              url: "/design/img/2020/media/IMG_7044.jpeg",
              thumb: "/design/img/2020/media-thumb/IMG_7044.jpg",
              title: "",
            },
            {
              url: "/design/img/2020/media/IMG_6917.jpeg",
              thumb: "/design/img/2020/media-thumb/IMG_6917.jpg",
              title: "",
            },
            {
              url: "/design/img/2020/media/IMG_6934.jpeg",
              thumb: "/design/img/2020/media-thumb/IMG_6934.jpg",
              title: "",
            },
            {
              url: "/design/img/2020/media/IMG_7036.jpeg",
              thumb: "/design/img/2020/media-thumb/IMG_7036.jpg",
              title: "",
            },
            {
              url: "/design/img/2020/media/IMG_6889.jpeg",
              thumb: "/design/img/2020/media-thumb/IMG_6889.jpg",
              title: "",
            },

            {
              url: "/design/img/2020/media/IMG_6924.jpeg",
              thumb: "/design/img/2020/media-thumb/IMG_6924.jpg",
              title: "",
            },
            {
              url: "/design/img/2020/media/IMG_6944.jpeg",
              thumb: "/design/img/2020/media-thumb/IMG_6944.jpg",
              title: "",
            },

            {
              url: "/design/img/2020/media/IMG_6891.jpeg",
              thumb: "/design/img/2020/media-thumb/IMG_6891.jpg",
              title: "",
            },
            {
              url: "/design/img/2020/media/IMG_6925.jpeg",
              thumb: "/design/img/2020/media-thumb/IMG_6925.jpg",
              title: "",
            },
          ]}
        />
      </div>
    </section>

    <Contact dark="true" />

    <Footer />
  </Layout>
);

export default Sfeer;
